import { get, toNumber } from 'lodash';
import { economicActivity as economicActivitySelector } from '../../selectors/activeInvoice';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import { clientDataAreEquals } from '../../utils/invoice/activeInvoice';

export class CostaRicaInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * This function takes the greater payment method, country, totalReceived,
   * and isElectronicPOSDocument as parameters and returns the payment method
   * to be used in the invoice.
   *
   * If the greater payment method is not in the mapping, it defaults to the
   * parent class implementation.
   * @param {string} greaterPaymentMethod The greater payment method.
   * @param {string} country The country.
   * @param {BigNumber} totalReceived The total received.
   * @param {boolean} isElectronicPOSDocument Whether the invoice is an electronic
   *     POS document.
   * @return {string} The payment method.
   */
  calculatePaymentMethod({
    greaterPaymentMethod,
    country,
    totalReceived,
    isElectronicPOSDocument,
  }) {
    const costaRicaPaymentMethods = {
      'debit-card': 'card',
      'credit-card': 'card',
      transfer: 'TRANSFER',
    };

    if (costaRicaPaymentMethods[greaterPaymentMethod]) {
      return costaRicaPaymentMethods[greaterPaymentMethod];
    }

    return super.calculatePaymentMethod({
      greaterPaymentMethod,
      country,
      totalReceived,
      isElectronicPOSDocument,
    });
  }
  /**
   * Returns the invoice with the stamp generateStamp field set to true
   * if the numberTemplate is electronic, or false if it's not.
   *
   * @param {Object} invoice The invoice to be modified.
   * @param {Object} numberTemplate The number template.
   * @return {Object} The modified invoice.
   */
  electronicInvoice({ invoice, numberTemplate }) {
    return {
      ...invoice,
      stamp: {
        generateStamp: !!numberTemplate?.isElectronic,
      },
    };
  }
  /**
   * Modify the invoice to create an electronic or non-electronic invoice for Costa Rica.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.invoice - The invoice object.
   * @param {Object} params.state - The global state object.
   * @param {boolean} params.isElectronic - The invoice is electronic or not.
   * @param {Object} params.numberTemplate - The number template object.
   * @param {Date} params.today - The current date.
   * @param {string} params.cfdiUse - The CFDI use for the invoice.
   * @param {Object} params.payments - Payments object.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
    payments,
    idPaymentMethodLocal,
  }) {
    const economicActivity = economicActivitySelector(state);
    const modifiedInvoice = {
      ...invoice,
      economicActivity: economicActivity
        ? toNumber(economicActivity.code)
        : null,
      saleCondition:
        Object.values(payments).reduce((a, b) => a + b) >= invoice.total
          ? '01'
          : '02',
      type: 'NATIONAL',
      dueDate: today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD'),
      idPaymentMethodLocal,
    };

    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    if (isElectronic) {
      return this.electronicInvoice({
        invoice: modifiedInvoice,
        numberTemplate,
      });
    }

    return modifiedInvoice;
  }
  /**
   * Returns a default client object for Costa Rica invoices.
   *
   * @param {Object} address - The address of the client.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @returns {Object} The default client object with preset values.
   */
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Cliente de contado',
      identificationObject: { type: 'CF', number: '100000000' },
      address,
      type: ['client'],
      email: 'ejemplo@ejemplo.com',
      ignoreRepeated: true,
    };
  }
  /**
   * Determines if the given client is the default client for Costa Rica.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.client - The client object to compare against the default.
   * @returns {boolean} - True if the client matches the default client criteria, otherwise false.
   */
  isDefaultClient({ client }) {
    const defaultC = this.defaultClient();
    return (
      clientDataAreEquals(defaultC.name, client.name) &&
      clientDataAreEquals(
        defaultC.identificationObject.type,
        client.identificationObject.type
      ) &&
      clientDataAreEquals(
        defaultC.identificationObject.number,
        client.identificationObject.number
      )
    );
  }

  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

import { get } from 'lodash';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import { electronicInvoicing } from '../../selectors/company';
import {
  ARG_DOCUMENT_TYPE,
  ARG_IVA_CONDITION,
  clientDataAreEquals,
  SALE_CONCEPT,
  VALIDATION_ERROR_MESSAGES,
} from '../../utils/invoice/activeInvoice';
import { toast } from '../../utils';
import { I18n } from 'aws-amplify';

export class ArgentinaInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Calculates the sale concept based on the invoice items.
   *
   * @param {Object} params - Params object.
   * @param {Array} params.items  - Array of invoice items.
   * @returns {string|null} - The sale concept or null if no items.
   */
  calcultateSaleConcept({ items }) {
    if (!items || !items.length) return null;

    let hasService = false;
    let hasProduct = false;

    for (const item of items) {
      const unit = item?.inventory?.unit;
      if (unit === 'service') {
        hasService = true;
      } else {
        hasProduct = true;
      }

      // Early return if both are found
      if (hasService && hasProduct) {
        return SALE_CONCEPT.PRODUCTS_SERVICES;
      }
    }

    if (hasService) return SALE_CONCEPT.SERVICES;
    if (hasProduct) return SALE_CONCEPT.PRODUCTS;
    return null;
  }
  /**
   * Modify the invoice to create an electronic invoice for Argentina.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.invoice - The invoice object.
   * @param {Date} params.today - The current date.
   * @param {Object} params.numberTemplate - The number template object.
   * @returns {Object} - The modified invoice.
   */
  electronicInvoice({ invoice, today, numberTemplate }) {
    const argentinaPaymentMethods = {
      'debit-card': 'DEBIT_CARD',
      'credit-card': 'CREDIT_CARD',
      transfer: 'TRANSFER',
    };

    const saleCondition =
      argentinaPaymentMethods[invoice?.paymentMethod] || 'CASH';

    const saleConcept = this.calcultateSaleConcept(invoice);
    const dateService =
      saleConcept === SALE_CONCEPT.PRODUCTS_SERVICES ||
      saleConcept === SALE_CONCEPT.SERVICES;
    return {
      ...invoice,
      saleCondition,
      saleConcept,
      startDateService: dateService ? today.format('YYYY-MM-DD') : null,
      endDateService: dateService ? today.format('YYYY-MM-DD') : null,
      stamp: {
        generateStamp: !!numberTemplate?.isElectronic,
      },
    };
  }

  calculatePaymentMethod({
    greaterPaymentMethod,
    country,
    totalReceived,
    isElectronicPOSDocument,
  }) {
    const argentinaPaymentMethods = {
      'debit-card': 'debit-card',
      'credit-card': 'credit-card',
      transfer: 'transfer',
    };

    if (argentinaPaymentMethods[greaterPaymentMethod]) {
      return argentinaPaymentMethods[greaterPaymentMethod];
    }

    return super.calculatePaymentMethod({
      greaterPaymentMethod,
      country,
      totalReceived,
      isElectronicPOSDocument,
    });
  }

  /**
   * Modify the invoice to create an electronic or non-electronic invoice for Argentina.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.invoice - The invoice object.
   * @param {Object} params.state - The global state object.
   * @param {boolean} params.isElectronic - The invoice is electronic or not.
   * @param {Object} params.numberTemplate - The number template object.
   * @param {Date} params.today - The current date.
   * @param {string} params.cfdiUse - The CFDI use for the invoice.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
    idPaymentMethodLocal,
  }) {
    const isCompanyElectronic = electronicInvoicing(state);
    const modifiedInvoice = {
      ...invoice,
      dueDate: today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD'),
      idPaymentMethodLocal,
    };

    delete modifiedInvoice.type;
    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    if (isCompanyElectronic) {
      return this.electronicInvoice({
        invoice: modifiedInvoice,
        numberTemplate,
        today,
      });
    }

    return modifiedInvoice;
  }
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Consumidor final',
      identification: { type: 'DNI', number: '1' },
      ivaCondition: ARG_IVA_CONDITION.FINAL_CONSUMER,
      address,
      type: ['client'],
      ignoreRepeated: true,
    };
  }

  /**
   * Determines if the given client is the default client for Argentina.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.client - The client object to compare against the default.
   * @returns {boolean} - True if the client matches the default client criteria, otherwise false.
   */
  isDefaultClient({ client }) {
    const defaultC = this.defaultClient();
    return (
      clientDataAreEquals(defaultC.name, client.name) &&
      clientDataAreEquals(
        defaultC.identification.type,
        client.identification.type
      ) &&
      clientDataAreEquals(
        defaultC.identification.number,
        client.identification.number
      )
    );
  }
  /**
   * Validates the invoice based on the IVA condition, document type, and electronic status.
   * Throws an error if the invoice cannot be created due to mismatched document types or IVA conditions.
   * Also handles electronic invoice validation and displays an error message if conditions are not met.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.invoice - The invoice object.
   * @param {string} params.ivaCondition - The IVA condition of the invoice.
   * @param {Object} params.numberTemplate - The number template object.
   * @param {boolean} params.isElectronic - Whether the invoice is electronic.
   * @throws {Error} If validation fails due to mismatched document types or IVA conditions.
   */
  modifyValidateInvoice({
    invoice,
    ivaCondition,
    numberTemplate,
    isElectronic,
  }) {
    let showError = false;
    const clientIvaCondition = get(invoice, 'client.ivaCondition');
    if (ivaCondition === ARG_IVA_CONDITION.IVA_RESPONSABLE) {
      if (
        get(numberTemplate, 'subDocumentType') === ARG_DOCUMENT_TYPE.INVOICE_A
      ) {
        if (
          !(
            clientIvaCondition === ARG_IVA_CONDITION.IVA_RESPONSABLE ||
            clientIvaCondition === ARG_IVA_CONDITION.UNIQUE_TRIBUTE_RESPONSABLE
          )
        )
          showError = true;
      } else if (
        get(numberTemplate, 'subDocumentType') === ARG_DOCUMENT_TYPE.INVOICE_B
      ) {
        if (
          !(
            clientIvaCondition === ARG_IVA_CONDITION.FINAL_CONSUMER ||
            clientIvaCondition === ARG_IVA_CONDITION.IVA_EXEMPT
          )
        )
          showError = true;
      }
    }

    if (
      ivaCondition === ARG_IVA_CONDITION.UNIQUE_TRIBUTE_RESPONSABLE ||
      ivaCondition === ARG_IVA_CONDITION.IVA_EXEMPT
    ) {
      if (
        get(numberTemplate, 'subDocumentType') === ARG_DOCUMENT_TYPE.INVOICE_C
      ) {
        if (
          !(
            clientIvaCondition === ARG_IVA_CONDITION.IVA_RESPONSABLE ||
            clientIvaCondition ===
              ARG_IVA_CONDITION.UNIQUE_TRIBUTE_RESPONSABLE ||
            clientIvaCondition === ARG_IVA_CONDITION.FINAL_CONSUMER ||
            clientIvaCondition === ARG_IVA_CONDITION.IVA_EXEMPT
          )
        )
          showError = true;
      }
    }

    if (showError) {
      toast.error({
        title: I18n.get(
          'fvArgValidateErrorTitle',
          'Tu factura no se creó por el tipo de documento'
        ),
        subtitle: (
          <>
            <p className='text-left h4 text-capitalize-first'>
              {I18n.get(
                'fvArgValidateError',
                'Revisá que el tipo de documento que tiene tu numeración corresponda con tu condición frente al IVA y la de tu cliente.'
              )}
            </p>
            <p className='text-left h4 text-capitalize-first'>
              {I18n.get(
                'fvArgValidateErrorMore',
                'Más información haciendo clic'
              )}{' '}
              <a
                href={
                  isElectronic
                    ? 'https://ayuda.alegra.com/es/crea-facturas-electr%C3%B3nicas-desde-el-punto-de-venta-argentina'
                    : 'https://ayuda.alegra.com/es/facturaci%C3%B3n-%C3%A1gil-en-el-punto-de-venta-pos-argentina'
                }
                target='_blank'
                rel='noreferrer'
              >
                {I18n.get('here', 'aquí')}
              </a>
            </p>
          </>
        ),
      });

      throw new Error(VALIDATION_ERROR_MESSAGES.INVOICE_DATA_ERROR);
    }

    if (isElectronic && !!get(invoice, 'stamp.generateStamp')) {
      let showError = false;
      const clientIvaCondition = get(invoice, 'client.ivaCondition');

      if (ivaCondition === ARG_IVA_CONDITION.IVA_RESPONSABLE) {
        if (
          get(numberTemplate, 'subDocumentType') === ARG_DOCUMENT_TYPE.INVOICE_A
        ) {
          if (
            !(
              clientIvaCondition === ARG_IVA_CONDITION.IVA_RESPONSABLE ||
              clientIvaCondition ===
                ARG_IVA_CONDITION.UNIQUE_TRIBUTE_RESPONSABLE
            )
          )
            showError = true;
        } else if (
          get(numberTemplate, 'subDocumentType') === ARG_DOCUMENT_TYPE.INVOICE_B
        ) {
          if (
            !(
              clientIvaCondition === ARG_IVA_CONDITION.FINAL_CONSUMER ||
              clientIvaCondition === ARG_IVA_CONDITION.IVA_EXEMPT
            )
          )
            showError = true;
        }
      }

      if (
        ivaCondition === ARG_IVA_CONDITION.UNIQUE_TRIBUTE_RESPONSABLE ||
        ivaCondition === ARG_IVA_CONDITION.IVA_EXEMPT
      ) {
        if (
          get(numberTemplate, 'subDocumentType') !== ARG_DOCUMENT_TYPE.INVOICE_C
        )
          showError = true;
      }

      if (showError) {
        toast.error({
          title: I18n.get(
            'feArgValidateErrorTitle',
            'Tu factura no se emitió por el tipo de documento'
          ),
          subtitle: (
            <>
              <p className='text-left h4 text-capitalize-first'>
                {I18n.get(
                  'feArgValidateError',
                  'Revisá que el tipo de documento que tiene tu numeración corresponda con tu condición frente al IVA y la de tu cliente.'
                )}
              </p>
              <p className='text-left h4 text-capitalize-first'>
                {I18n.get(
                  'feArgValidateErrorMore',
                  'Más información haciendo clic'
                )}{' '}
                <a
                  href='https://ayuda.alegra.com/es/crea-facturas-electr%C3%B3nicas-desde-el-punto-de-venta-argentina'
                  target='_blank'
                  rel='noreferrer'
                >
                  {I18n.get('here', 'aquí')}
                </a>
              </p>
            </>
          ),
        });

        throw new Error(VALIDATION_ERROR_MESSAGES.INVOICE_DATA_ERROR);
      }
    }
  }

  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

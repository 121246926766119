import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
  isTicketOfflineEnabled,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';
import { get } from 'lodash';
import dayjs from 'dayjs';

export const OFFLINE_ACTIVE_COUNTRIES = [
  COUNTRIES.COLOMBIA,
];

export const OFFLINE_ACTIVE_IDS = [
  '1034032'
];

const DEPLOY_GROUP_DATE = '2023-07-01';

const useElectronicInvoiceOfflineGroup = () => {
  const [isElectronicOffline, setIsElectronicOffline] = useState(false);
  const [isInGroup, setIsInGroup] = useState(false);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const isEnabled = useSelector(isTicketOfflineEnabled);
  const registryDate = get(company, 'registryDate');

  useEffect(() => {
    if (
      (OFFLINE_ACTIVE_COUNTRIES.includes(country)
      || OFFLINE_ACTIVE_IDS.includes(company?.id))
      && isEnabled
    ) {
      setIsElectronicOffline(true);
    }

    if (OFFLINE_ACTIVE_COUNTRIES.includes(country)
      || OFFLINE_ACTIVE_IDS.includes(company?.id)) {
      setIsInGroup(true);
    }
  }, [company, country, isEnabled]);

  return {
    isElectronicOffline,
    isInGroup
  };
};

export default useElectronicInvoiceOfflineGroup;

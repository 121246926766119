import es from "./es";

const dictionary = {
  ...es,
  "aboutPOS": "¿Cómo usar Alegra POS?",
  "actualStation": "Sucursal actual",
  "addWarehouse": "Agregar almacén",
  "AlegraAccounting": "Administración",
  "AlegraAccounting.description": "Reportes, control de inventario y más",
  "AlegraPOS": "POS",
  "AlegraPOS.description": "Agiliza tus ventas y controla tu efectivo",
  "AlegraShop.description": "Crea tu tienda online fácil y rápido",
  "alegraWeekIsHere2": "Compra dos meses de tu plan favorito de Alegra POS y recibe el",
  "allInvoicesMadeFrom": "Todos los tickets realizados desde",
  "AssociatePriceListsToTerminalsNotification.title": "¡Configura tu lista de precios favorita! 💡",
  "AssociatePriceListsToTerminalsNotification.body": "Puedes definir una lista de precios en tu sucursal para que venga seleccionada por defecto y ahorrar tiempo al vender.",
  "AssociatePriceListsToTerminalsNotification.action": "Ir a configurarla",
  "cashManagementExpenseHelp": "Selecciona la cuenta contable por defecto para el registro de tus gastos desde el POS",
  "cashToInvoices": "Crédito a tickets",
  "changeInvoiceError": "No pudimos modificar el ticket. Inténtalo de nuevo",
  "checkIn": "Vender",
  "checkInSection": "Crear ticket",
  "choosePlanNote1": "(*) Tu plan de Alegra POS dependerá de tus ingresos mensuales.",
  "clientInvoice": "ticket del cliente",
  "createItemSubmitError1Message":
    "Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en almacén de las que elijas.",
  "companySettingInfo": "Ingresa tu información y adapta Alegra POS a tu negocio",
  "companySettingSubtitle": "Actualiza los datos de tu negocio que aparecerán en tus tickets",
  "configureStationWarehouse": "Configurar almacén",
  "createFirstInvoice": "Crear primer ticket",
  "createInvoiceError": "Error en la creación de ticket",
  "createWarehouse": "Crear almacén",
  "createWarehouseError": "Error en la creación de almacén",
  "creditToInvoice": "Crédito a ticket",
  "decimalPrecisionTooltip": "Selecciona el número de decimales que utilizarás para los precios de tus productos y valores de tus tickets",
  "defaultCashBankTutorial": "Esta cuenta de banco se asigna por defecto a cada sucursal para que tengas un mejor control del efectivo.",
  "discountCalculationHelTooltip.link": "https://ayuda.alegra.com/es/edita-tus-productos-mientras-vendes-pos",
  "distributeInWarehouses": "Distribuir en almacenes",
  "editInvoice": "Editar ticket",
  "electronicInvoice": "ticket electrónico",
  "electronicPaymentsTotalMustBeLTETotal": "El total de los pagos electrónicos debe ser igual o menor al total del ticket",
  "electronicSalesInvoice": "ticket electrónico de venta",
  "generalStationInfoTutorial": "Configura los datos de tu sucursal y la información necesaria para crear tus documentos",
  "getIntoOtherTerminal": "Ingresar a otra sucursal",
  "happyWeekSubtitle3": "de Alegra POS y recibe el",
  "howToEmit": "Aprender a timbrarlas",
  "immediatePaymentSale": "Ventas de contado",
  "includeAccountStatementSubtitle": "Al enviar una factura al correo, esta irá acompañada del resumen de todos sus tickets",
  "initAppOfflineDataErrorMessage": "Hay datos que no han sido sincronizados pertenecientes a otra sucursal. Si continúa podría perderlos.",
  "inventoryWarehouses": "Almacenes de inventario",
  "indicateTheVariantQuantity": "Indica aquí las cantidades en tus almacenes",
  "invoice": "ticket",
  "invoiceNumbering": "Numeración de ticket",
  "invoiceModalTitle.saleTicket": "Ticket",
  "invoiceOfSale": "Ticket de venta",
  "invoicePrintFooter": "Generado en Alegra POS - alegra.com/pos",
  "invoicePrintSettingSubtitle": "Elige los parámetros que utilizarás y serán visibles en tus tickets.",
  "invoiceSaved": "ticket guardado",
  "invoiceSavedInOffline": "Tu ticket se está sincronizando, no te detengas, sigue facturando",
  "invoicesPerMonth": "facturas globales por mes",
  "invoiceSyncErrorOfflineSubtitle": "Podrás consultar el ticket en el historial de ventas en cuanto recuperes tu conexión.",
  "invoiceSyncErrorSubtitle": "Uno de los tickets de venta está pendiente por sincronizar. Más detalles",
  "invoiceType": "Tipo de ticket",
  "itemVariantHelp": "Indica aquí las cantidades en tus almacenes",
  "itemCreatedSuccessfullyMessageAlter": "Ten en cuenta que no lo asociaste al almacén de tu sucursal actual y para venderlo debes ",
  "itemCreatedSuccessfullyMessageLink": "cambiar de sucursal o editar esta.",
  "itemCategoriesAreEmpty": "Las categorías que seleccionaste están vacías en el almacén de esta sucursal. 🔍",
  "itemCategoryIsEmpty": "La categoría {} está vacía en el almacén de esta sucursal. 🌪️",
  "lambdaError.electronicInvoicing": "El timbrado de facturas tiene intermitencia y trabajamos para solucionarla. Por favor, intenta de nuevo en unos minutos.",
  "limitedPlanNotification": "¡Actualiza tu suscripción y no pares tus ventas!🚨",
  "limitedPlanNotification.description": "Superaste las características de tu plan y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.",
  "limitedPlanNotification.action": "Actualizar suscripción",
  "limitedQueryPlan": "¡Actualiza tu suscripción y no pares tus ventas!🚨",
  "limitedQueryPlan.description": "Te encuentras en un Plan Consulta y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.",
  "limitedQueryPlan.action": "Actualizar suscripción",
  "manageStations": "gestionar sucursales",
  "multicurrencyFieldHelp": "Genera tus tickets POS en diferentes monedas",
  "myWarehouses": "mis almacenes",
  "newClientSubtitle": "Crea los contactos que asociarás en tus facturas de venta.",
  "newInventoryAdjustmentSubtitle": "Modifica las cantidades de los productos que tienes en el almacén de tu sucursal actual.",
  "newWarehouse": "Nuevo almacén",
  "newStation": "Nueva sucursal",
  "noEligibleStation": "No hay sucursales para elegir",
  "noEligibleStationMessage": "Verifica que hay por lo menos una sucursal activa",
  "noSelectedInvoice": "Selecciona un ticket para conocer el detalle o crea una nueva venta ahora.",
  "noStationPermissionError": "¿Necesitas acceder a esta sucursal?",
  "noStationPermissionWithNameError": "¿Necesitas ingresar a la sucursal{}?",
  "noStationPermissionErrorMessage": "Solicita acceso a un perfil administrador o ingresa desde otra sucursal en la que tengas permiso.",
  "noStationPermissionErrorMessageEnd": "desde la configuración de las sucursales.",
  "notInvoiceYet": "Crea tu primera venta en segundos.",
  "notItemsYet": "Para crear tu primera venta te invitamos a agregar un producto o servicio. 🏷️",
  "numerationsSettingTooltip": 'Gestiona desde "Alegra Administración" las numeraciones que usas para facturar en tu negocio.',
  "onboardingWizardSubtitleStep1": "Dale play y descubre lo fácil que vas a gestionar tu negocio con Alegra POS",
  "onboardingEndSubtitle": "Crear tickets en segundos, controlar tu inventario y gestionar el efectivo de tu negocio ¡Ya es posible con Alegra POS! 🙌",
  "onboardingWelcomeSubtitle": "Conoce en este recorrido cómo <b>crear más rápido tus tickets</b> y empieza a darle superpoderes a tu negocio.",
  "onboardingWelcomeTitle": "¡Te damos la bienvenida a Alegra POS! 😉",
  "onboarding.video.url": "https://www.youtube.com/embed/d0x-2M8SHWg",
  "onboarding.video.id": "d0x-2M8SHWg",
  "payInvoice": "Pagar ticket",
  "paymentsLoadError": "Error cargando las operaciones de efectivo de la sucursal",
  "pendingInvoiceSettingsTutorial": "Crea varios tickets a la vez para manejar por ejemplo el control de mesas.",
  "planInvoicesFeature": "{} facturas globales",
  "pos": "POS",
  "POSSetting": "Funcionalidades POS",
  "POSSettingTutorial": "Elije la configuración que tendrán todas las sucursales de tu POS",
  "POSStations": "Sucursales POS",
  "POSTutorial": "Tutorial POS",
  "preInvoice": "Pre-Ticket",
  "printDescription": "Incluir descripción de tus productos en tus tickets",
  "printedRepresentationOfElectronicInvoice": "Representación impresa de ticket electrónico",
  "printLogo": "Agregar tu logo en la impresión de tus tickets",
  "printTemplateHelp": "Selecciona la plantilla para la impresión de tus tickets.",
  "printTemplateSettingInfo": "Configura cómo se verán los tickets de tu negocio",
  "printUnitPrice": "Mostrar precio unitario de tus productos en tus tickets",
  "printPersonalizedInvoices": "Personalización de tickets",
  "printPersonalizedInvoicesHelp": "Dale tu toque a la parte final de tus tickets de venta con una frase, imagen o ambas.",
  "printMeasurement": "Incluir la unidad de medida en tus tickets",
  "printMeasurementHelp": "Has visible la unidad de medida de tus productos y servicios.",
  "printLines": "Mostrar total de líneas y productos en tus tickets",
  "printLinesHelp": "Incluye el número de líneas de tu ticket y el total de productos vendidos.",
  "productDistributionHelp.mid": " cómo distribuir en diferentes almacenes",
  "productKey": "Clave de producto SAT",
  "productKeyHelp": "Conoce {} del SAT a tus productos y servicios.",
  "productKeyHelp1": "cómo agregar la clave",
  "refundsLoadError": "Error cargando las devoluciones de la sucursal",
  "renamePendingInvoice": "Renombrar ticket pendiente",
  "reviewNameAndRFCConfigured": "Revisa el nombre y el RFC que configuraste",
  "reviewNameAndRFCConfigured.description": "Al parecer alguno de estos datos es diferente al que se encuentra registrado en el SAT",
  "saleTicket": "Ticket",
  "selectAStation": "Selecciona una sucursal",
  "selectAStationHeadline": "Selecciona la sucursal para ejecutar tus operaciones",
  "selectProductsToCreateYourInvoice": "Selecciona al menos un producto para crear tu ticket",
  "sellerCreatedSuccessfullyMessage": "Ya puedes asociar en tus tickets a {} como vendedor.",
  "serviceKey": "Clave de servicio SAT",
  "setStationWarehouse": "Configura un almacén para tu sucursal",
  "setStationWarehouseHelp": "Parece que se desactivó o eliminó el almacén que estaba asociado a tu sucursal, selecciona uno para seguir vendiendo.",
  "shiftSettingTutorial": "Elige el banco de apertura y de cierre de los turnos para esta sucursal. ",
  "shiftsLoadError": "Error cargando el historial de turnos de la sucursal",
  "station": "Sucursal",
  "stationActivated": "Sucursal activada con éxito",
  "stationCreatedSuccessfullyMessage": "La sucursal {} ya está disponible para que gestiones las ventas de tu negocio.",
  "stationDeactivated": "Sucursal desactivada con éxito",
  "stationDeleted": "Sucursal eliminada con éxito",
  "stationDeletedError": "Error eliminando sucursal",
  "stationName": "Nombre de tu sucursal",
  "stationSettingTitle": "Sucursal actual",
  "stationSettingSubtitle": "Configura los datos de tu sucursal y la información necesaria para facturar.",
  "stations": "Sucursales",
  "stationSetting": "Configuración de sucursal",
  "stationsSettingSubtitle": "Crea y administra las sucursales que usas para la gestión de tus puntos de venta.",
  "stationsSettingTitle": "Gestiona tus sucursales POS",
  "stationsForYourPointOfSale": "Sucursales para tus puntos de venta",
  "stationStatusError": "Error cambiando estado de sucursal",
  "stationsLoadError": "Sucedió un error cargando las sucursales",
  "stationUpdated": "Sucursal actualizada",
  "stationWarehouseDeletedError": "Solo puedes eliminar almacenes que no estén asociados a una sucursal.",
  "stationWarehouseStatusError": "Solo puedes desactivar almacenes que no estén asociados a una sucursal.",
  "subscriptionIndications": "Con Alegra POS vendes en segundos y haces crecer tu negocio. Conoce a continuación los detalles de tu suscripción:",
  "taxesSettingTooltip": 'Configura desde "Alegra Administración" los impuestos que necesitas para facturar en tu punto de venta.',
  "tours.onboarding.title.4": "¡Boom, tu primera venta! 🎉",
  "tours.onboarding.content.4": "Con esta opción creas tu ticket, eliges el método de pago e imprimes tu recibo.",
  "updateWarehouse": "Actualizar almacén",
  "updateStationError": "Error al cambiar los datos de la sucursal",
  "updateWarehouseError": "Error al cambiar los datos del almacén",
  "updateYourPlanNotification": "¡Actualiza tu suscripción y no pares tus ventas!🚨",
  "userAssociationTutorial": "elige los usuarios que tendrán acceso al uso de esta sucursal",
  "userNotAllowed.pos-station.edit": "No tienes permisos suficientes para editar la información de la sucursal",
  "userNotAllowed.pos-station.index": "No tienes permiso para ver las sucursales",
  "userNotAllowed.pos-station.view": "No tienes permisos para ver detalle de sucursales",
  "userNotAllowed.warehouses.add": "No tienes permisos para agregar almacenes",
  "userNotAllowed.warehouses.delete": "No tienes permisos para eliminar almacenes",
  "userNotAllowed.warehouses.edit": "No tienes permisos para editar almacenes",
  "void": "Cancelar",
  "videoIntroductionPOS": "Video introductorio POS",
  "warehouse": "Almacén",
  "warehouses": "Almacenes",
  "warehouseActivated": "almacén activado con éxito",
  "warehouseChangeError": "No tienes los permisos para ver productos de otros almacenes.",
  "warehouseChangeInfo": "Cambia de almacén para visualizar los productos presentes en cada uno.",
  "warehouseCreatedSuccessfullyMessage": "El almacén {} ya está disponible para que distribuyas tu inventario.",
  "warehouseDeactivated": "almacén desactivado con éxito",
  "warehouseDeleted": "almacén eliminado con éxito",
  "warehouseDeletedError": "error eliminando almacén",
  "warehouseQuantity": "Cantidad en Almacén",
  "warehouseSetting": "Configuración de almacén",
  "warehousesLoadError": "Sucedió un error cargando los almacenes",
  "warehousesSettingSubtitle": "Administra los almacenes de tu negocio",
  "warehousesInventorySubtitle": "Crea almacenes para gestionar tu inventario en diferentes lugares de almacenamiento y distribución.",
  "warehouseStatusError": "error cambiando estado del almacén",
  "warehouseUpdated": "almacén actualizado con éxito",
  "warehouse_name": "Almacén",
  "welcomeToAlegraPOS": "¡Bienvenido a Alegra POS!",
  "welcomeToYourPOSSystem": "¡Bienvenido a tu sistema POS!",
  "youDontHaveCreatedStations": "No has creado tu primera sucursal, créala aquí",
  "youDontHaveCreatedWarehouses": "No has creado tu primer almacén, créala aquí.",
  "youHavePendingInvoicesToEmit": "¡Tienes facturas por timbrar! 🚨",
  "youHavePendingInvoicesToEmit.description": "Aprende a identificar las facturas que tienes “Por emitir” y envíalas al SAT con un clic.",
  "youHavePendingInvoicesToEmit.link": "https://ayuda.alegra.com/es/consulta-el-estado-de-timbrado-de-tus-facturas-en-alegra-pos-mex",
  "yourPOSSystem": "tu sistema POS",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Timbra tus CFDIs en segundos",
  "AlegraAccounting.new": "Contabilidad",
  "AlegraAccounting.description.new": "Contabiliza, factura y gestiona tu stock",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Agiliza tus ventas y controla tu efectivo",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "AlegraInvoicing.description.new": "Timbra tus CFDIs en segundos",
  "terminal_summary": "Resumen de sucursal",
  "globalInvoices": "Facturas globales",
  "stamped": "Timbrada",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Contabilidad para que los importes de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Contabilidad tomas el control de tu negocio, envías facturas electrónicas al SAT y accedes a reportes administrativos en tiempo real. ​​¡Experimenta el poder de la contabilidad inteligente!",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.invoice.supTitle": "Ahorra tiempo con",
  "solutionModal.invoice.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.invoice.buttonSecondaryText": "Más información",
  "solutionModal.invoice.description": "Genera en segundos tus facturas y tickets con el software de Facturación más veloz de México. Sistema 100% adaptado al SAT.",
  "solutionModal.invoice.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Emite tus tickets de venta en segundos, controla todas tus sucursales desde un solo lugar y genera reportes de ventas e inventario en tiempo real.",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "userNotAllowed.edit.saleTicket": "Necesitas permiso del administrador para editar tickets.",
  "global-invoice-empty-state-first-invoice-title": "¡Crea tu primer ticket al instante! ⚡",
  "global-invoice-empty-state-first-invoice-description": "Registra tus tickets de venta y tímbralos en facturas globales",
  "global-invoice-empty-state-title": "Sin tickets por timbrar 🚫",
  "global-invoice-empty-state-description": "Ajusta los filtros o crea nuevos tickets en segundos",
  "item-kit": "kit",
  "contactsDescription": "Gestiona la información de los clientes que podrás asociar en tus tickets de venta.",
  'invoiceCreatedElectronicMessageTitle':"Timbrando",
  'invoiceCreatedElectronicMessage':"Estamos enviando tu factura al SAT⚡",
  "numerationElectronicIntermitenceError": "Timbrado de facturas con intermitencias. Te avisaremos cuando se restablezca el servicio.",
  "invoiceTicket": "<b>Ticket N°</b>",
  "invoiceBill": "<b>Factura de venta N°</b>",
  "warehouseChangeMessage": "Puedes cambiar de almacén para revisar los productos de cada uno.",
  "warehouseChangeErrorNew": 'Te falta el permiso de "ver detalle" en almacenes para revisar las demás.',
  "goAppDesktopCtaOne": "Abrir aplicación de PC",
  "POSIntroductoryCourse": "Curso introductorio del POS",
  "terminalHasAssociatedShifts.message": "Solo puedes eliminar sucursales en las que no hayas usado la función de turnos.",
  "terminalHasAssociatedInvoices.message": "Solo puedes eliminar sucursales en las que no hayas realizado ventas.",
  "stationDeletedInterrupted.message": "La eliminación de tu sucursal no pudo completarse. Inténtalo de nuevo más tarde.",
  "thisIsTheUniqueTerminal": "Esta es tu única sucursal activa. Para eliminarla, primero crea otra que puedas usar para vender.",
  "thisIsTheTerminalYouAre": "Esta es tu sucursal actual. Es necesario que cambies a otra para poder eliminarla.",
  "globalDiscountsNotificationDescription": "Aplica descuentos a todos los ítems de una venta de manera fácil y rápida. ¡Pruébalo ya!",
  "globalDiscountsDescription": "Aplica descuentos a todos los productos y servicios de una venta de manera rápida y fácil.",
  "globalDiscountsAmountError": "El valor del descuento es igual o superior al subtotal de los ítems seleccionados.",
  "itemsAlreadyHasDiscount": "Los ítems marcados con ⚠️ ya tienen descuentos aplicados. Al añadir un descuento global, estos se reemplazarán.",
  "itemsAlreadyWithDiscount": "Aplica descuentos a todos los ítems de tu factura con un clic. ✨ ¡Te mostramos cómo!",
  "documentType.invoice": "Factura",
  "documentType.saleTicket": "Ticket",
  "sendToEmissionEntity": "Enviando al SAT...",
  "invoiceSaved.title": "Factura guardada",
};

export default dictionary;

import { get } from 'lodash';
import { COUNTRIES } from '../../../utils/enums/countries';
import dayjs from 'dayjs';

const COUNTRIES_AVAILABLE = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.USA,
  COUNTRIES.PERU,
  COUNTRIES.PANAMA,
  COUNTRIES.REPUBLICA_DOMINICANA,
  COUNTRIES.SPAIN,
  COUNTRIES.ARGENTINA,
  COUNTRIES.COSTA_RICA,
  COUNTRIES.MEXICO,
  COUNTRIES.COLOMBIA,
];
export const showNewInvoiceModal = ({ country, company }) => {
  return COUNTRIES_AVAILABLE.includes(country);
};

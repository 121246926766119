import { COUNTRIES } from "../enums/countries";

export const availableInstallers = ['msi'];
export const availableDomain = 'alegra.com';

const countriesAvailable = [COUNTRIES.PANAMA, COUNTRIES.INTERNATIONAL, COUNTRIES.PERU, COUNTRIES.SPAIN, COUNTRIES.ARGENTINA];

export function checkCanDownloadApp(installerType, email, country) {
  return (availableInstallers.includes(installerType) && email.includes(availableDomain))
    || (availableInstallers.includes(installerType) && countriesAvailable.includes(country));
}
import {
  Checkbox,
  Input,
  Textarea,
  Radio,
  Dropdown,
  Switch,
  Select,
  DatePicker,
  InputDecimal,
} from '@alegradev/smile-ui-react';
import { get, isArray, isNumber, isString } from 'lodash';
import { memo } from 'react';
import { Field } from 'react-final-form';

export const MemoizedField = memo(
  ({ name, render, placeholder, type, ...rest }) => (
    <Field
      name={name}
      render={render}
      placeholder={placeholder}
      size="small"
      type={type}
      {...rest}
    />
  )
);

export const renderField = (props) => {
  const {
    meta: { touched, error },
    input,
    fieldType,
    separator,
    changeAction,
    ...rest
  } = props;

  const hasError =
    (touched || get(props, 'input.name', '') === 'creditLimit') && error;

  const supportText = hasError ? error : rest?.supportText;

  const inputProps = {
    ...input,
    ...rest,
  };

  const inputDeciamlProps = {
    name: inputProps?.name,
    defaultValue: inputProps?.value,
    onChange: inputProps?.onChange,
    hasError,
    supportText,
    separator,
    ...rest,
  };

  switch (fieldType) {
    case 'checkbox':
      return <Checkbox {...inputProps} />;
    case 'textarea':
      return (
        <Textarea
          {...inputProps}
          supportText={supportText}
          hasError={hasError}
        />
      );
    case 'radio':
      return <Radio {...inputProps} />;
    case 'switch':
      return <Switch {...inputProps} />;
    case 'rselect':
      return <Select {...inputProps} />;
    case 'date':
      return <DatePicker {...inputProps} />;
    case 'decimal':
      return <InputDecimal {...inputDeciamlProps} />;
    case 'select':
      const inputOptions = !!input?.value
        ? isArray(input?.value)
          ? input?.value
          : isString(input?.value)
            ? input?.value?.split('%s%')
            : isNumber(input?.value)
              ? [input?.value]
              : []
        : [];

      return (
        <Dropdown
          name={inputProps.name}
          placeholder={inputProps.placeholder || ''}
          defaultOptions={inputOptions}
          label={props.label}
          options={props.options || []}
          canClear={props.canClear}
          onChange={(values) => {
            props?.input?.multiple
              ? inputProps?.onChange(
                  values
                    ?.map((value) => {
                      if (changeAction) changeAction(value);
                      return value?.value;
                    })
                    .join('%s%')
                )
              : [
                  inputProps?.onChange(values[0]?.value),
                  changeAction && changeAction(values[0]?.value),
                ];
          }}
          onClear={() => props?.onClear()}
          hasError={hasError}
          errorMessage={supportText}
          defaultFavoriteOption={props.defaultFavoriteOption}
          disabled={props.disabled}
          required={props.required}
          footer={props.footer}
          search={props.search}
          dropdownLabel={props.dropdownLabel}
          noOptionsText={props.noOptionsText}
          multiple={props?.input?.multiple}
          canFavorite={props.canFavorite}
          forceDefaultOptions={props.forceDefaultOptions}
          loading={props.loading}
          magicLoading={props.magicLoading}
          size={props.size}
        />
      );
    default:
      return (
        <Input {...inputProps} supportText={supportText} hasError={hasError} />
      );
  }
};
